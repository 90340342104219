// LocationGetter.js
import React from 'react';

const LocationGetter = () => {
  const getLocationCoordinates = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            resolve([latitude, longitude]);
          },
          (error) => {
            console.error('Geolocation error:', error);
            resolve([0, 0]); // Fallback to 0,0 if there's an error
          }
        );
      } else {
        console.error('Geolocation not supported');
        resolve([0, 0]); // Fallback to 0,0 if geolocation is not supported
      }
    });
  };

  return { getLocationCoordinates };
};

export default LocationGetter;