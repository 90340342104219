import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ThankYouBugReportPage.module.css';

const ThankYouPage = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Thank You for Your Report</h1>
      <p className={styles.message}>
        We appreciate you taking the time to submit a bug report. Our team will review it and get back to you as soon as possible.
      </p>
      <Link to="/feed" className={styles.homeLink}>Return to Home</Link>
    </div>
  );
};

export default ThankYouPage;