import { createSlice } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';

const initialState = {
  id: null,
  username: null,
  avatarUrl: null,
  token: null,
  latitude: null,
  longitude: null,
  isLoggedOut: false, // Add this flag
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload, isLoggedOut: false };
    },
    clearUser: (state) => {
      return { ...initialState, isLoggedOut: true };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      if (action.payload && action.payload.user) {
        // Only rehydrate if the user wasn't logged out
        if (!action.payload.user.isLoggedOut) {
          return { ...state, ...action.payload.user, isLoggedOut: false };
        }
      }
      return state;
    });
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;