import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './LikesModal.module.css';
import config from '../config';
const LikesModal = ({ 
  isOpen, 
  onClose, 
  likes, 
  onLoadMore, 
  hasMore, 
  isLoading 
}) => {
  const loadMoreRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          onLoadMore();
        }
      },
      { threshold: 0.1 }
    );

    const currentElement = loadMoreRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [hasMore, isLoading, onLoadMore]);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <h3>Likes</h3>
          <button onClick={onClose} className={styles.closeButton}>×</button>
        </div>
        
        <div className={styles.likesContainer}>
          {likes.map(like => (
            <div 
              key={`${like.post_id}-${like.user_id}`} 
              className={styles.likeItem}
            >
              <img 
                src={`${config.mediaApiUrl}/${like.user.avatar}`} 
                alt={like.user.username} 
                className={styles.avatar}
              />
              <span className={styles.username}>{like.user.username}</span>
            </div>
          ))}
          
          {isLoading && (
            <div className={styles.loading}>Loading more likes...</div>
          )}
          
          {hasMore && <div ref={loadMoreRef} className={styles.loadMoreTrigger} />}
          
          {!hasMore && likes.length > 0 && (
            <div className={styles.endMessage}>No more likes to show</div>
          )}
        </div>
      </div>
    </div>
  );
};

LikesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  likes: PropTypes.arrayOf(
    PropTypes.shape({
      post_id: PropTypes.number.isRequired,
      user_id: PropTypes.number.isRequired,
      timestamp: PropTypes.string.isRequired,
      user: PropTypes.shape({
        username: PropTypes.string.isRequired,
        avatar: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LikesModal;