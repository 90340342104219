import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import styles from './Messages.module.css';
import { ReactComponent as ReportIcon } from '../assets/flag-solid.svg';
import { ReactComponent as ThreeDotsIcon } from '../assets/dots.svg';
import config  from '../config'; 
const WEBSOCKET_API_URL = `${config.websocketApiUrl}`


const Messages = () => {
  const { userId } = useParams();
  const location = useLocation();
  const messagesContainerRef = useRef(null);
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const { username, userId: otherUserId, avatar } = location.state || {};
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [connectionStatus, setConnectionStatus] = useState('disconnected');
  const [conversationId, setConversationId] = useState(null);
  const socket = useRef(null);
  const messagesEndRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Set to true initially
  const [autoScroll, setAutoScroll] = useState(true);

   const scrollToBottom = (behavior = 'smooth') => {
    const container = messagesContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  // Handle scroll events to determine if user has scrolled up
  const handleScroll = () => {
    const container = messagesContainerRef.current;
    if (container) {
      const isScrolledNearBottom = 
        container.scrollHeight - container.clientHeight - container.scrollTop < 100;
      setAutoScroll(isScrolledNearBottom);
    }
  };

  // Scroll on new messages if autoScroll is true
  useEffect(() => {
    if (autoScroll) {
      scrollToBottom();
    }
  }, [messages, autoScroll]);

  // Scroll on initial load
  useEffect(() => {
    scrollToBottom('auto');
  }, []);

  useEffect(() => {

    socket.current = io(WEBSOCKET_API_URL, {
      withCredentials: true,
       transports: ['websocket'],
      upgrade: false,
      path: '/api/websocket' 
    });

    socket.current.on('connect', () => {
      console.log('Socket.IO connected');
      setConnectionStatus('connected');
      socket.current.emit('join', { user_id: otherUserId });
    });

    socket.current.on('joined', (data) => {

      setConversationId(data.conversation_id);
      // Fetch initial messages
      socket.current.emit('get_messages', { conversation_id: data.conversation_id });
    });

    socket.current.on('initial_messages', (initialMessages) => {

      if (Array.isArray(initialMessages)) {
        setMessages(initialMessages);
        setIsLoading(false)
      } else {
        console.error('Received invalid initial messages:', initialMessages);
      }
    });
    socket.current.on('user_typing', () => {
    setIsTyping(true);
    // Hide typing indicator after 3 seconds
    setTimeout(() => setIsTyping(false), 3000);
  });

    socket.current.on('message', (message) => {

      setMessages(prevMessages => [...prevMessages, message]);
    });

    socket.current.on('error', (error) => {
      
      setConnectionStatus('error');
    });

    socket.current.on('disconnect', () => {

      setConnectionStatus('disconnected');
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [otherUserId]);

  const sendMessage = () => {
    if (inputMessage.trim() !== '' && socket.current && socket.current.connected && conversationId) {
      const message = {
        conversation_id: conversationId,
        content: inputMessage
      };
      
      socket.current.emit('message', message);
      setInputMessage('');
    } else {
      console.error('Cannot send message:', { 
        inputMessage: inputMessage.trim() !== '',
        socketConnected: socket.current && socket.current.connected,
        conversationId: conversationId
      });
    }
  };


  return (
  <div className={styles.chatContainer}>
    <div className={styles.chatHeader}>
      <div className={styles.userInfo}>
        <div className={styles.avatarContainer}>
          {avatar ? (
            <>
              <img 
                src={`${config.mediaApiUrl}/${avatar}`} 
                alt={username} 
                className={styles.avatarImage}
                style={{ display: avatarLoaded ? 'block' : 'none' }}
                onLoad={() => setAvatarLoaded(true)}
              />
              {!avatarLoaded && <div className={styles.spinner} />}
            </>
          ) : (
            <div className={styles.fallbackAvatar}>
              {username ? username[0].toUpperCase() : '?'}
            </div>
          )}
        </div>
        <div className={styles.userDetails}>
          <span className={styles.username}>{username || userId}</span>
        </div>
      </div>
      <div className={styles.iconContainer}>
        <ReportIcon className={styles.icon} />
        <ThreeDotsIcon className={`${styles.icon} ${styles.threeDotsIcon}`} />
      </div>
    </div>

    <div className={styles.messagesContainer} ref={messagesContainerRef} onScroll={handleScroll}>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <div className={styles.spinner} />
        </div>
      ) : messages.length === 0 ? (
        <div className={styles.noMessages}>
          <div className={styles.noMessagesContent}>
            <div className={styles.avatarLarge}>
              {avatar ? (
                <>
                  <img 
                    src={`${config.mediaApiUrl}/${avatar}`} 
                    alt={username} 
                    className={styles.avatarImageLarge}
                    style={{ display: avatarLoaded ? 'block' : 'none' }}
                  />
                  {!avatarLoaded && <div className={styles.spinner} />}
                </>
              ) : (
                <div className={styles.fallbackAvatarLarge}>
                  {username ? username[0].toUpperCase() : '?'}
                </div>
              )}
            </div>
            <span className={styles.noMessagesUsername}>{username || userId}</span>
            <span className={styles.noMessagesText}>Send your first message!</span>
          </div>
        </div>
      ) : (
        <>
          {messages.map((message, index) => (
            <div 
              key={index} 
              className={`${styles.message} ${message.sender_id === otherUserId ? styles.received : styles.sent}`}
            >
              {message.content}
            </div>








          ))}
          {isTyping && (
            <div className={styles.typingIndicator}>
              <div className={styles.typingDot}></div>
              <div className={styles.typingDot}></div>
              <div className={styles.typingDot}></div>
            </div>
          )}


          {!autoScroll && messages.length > 0 && (
        <button 
          className={styles.scrollToBottomButton}
          onClick={() => {
            scrollToBottom();
            setAutoScroll(true);
          }}
        >
          ↓
        </button>
      )}

        </>
      )}
      <div ref={messagesEndRef} />
    </div>

    <div className={styles.inputContainer}>
      <input
        className={styles.input}
        type="text"
        value={inputMessage}
        onChange={(e) => {
          setInputMessage(e.target.value);
          if (e.target.value && socket.current && conversationId) {
            socket.current.emit('typing', { conversation_id: conversationId });
          }
        }}
        onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
        placeholder="Message..."
        aria-label="Message input"
      />
      <button 
        className={`${styles.sendButton} ${inputMessage.trim() ? styles.active : ''}`}
        onClick={sendMessage} 
        disabled={connectionStatus !== 'connected' || !conversationId || !inputMessage.trim()}
      >
        Send
      </button>
    </div>
  </div>
);


};

export default Messages;
