import React, { useState } from 'react';
import styles from './BugReport.module.css';
import { apiCall } from '../utils/api';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const BugReportForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        steps: '',
        expectedBehavior: '',
        actualBehavior: '',
        deviceInfo: '',
        email: '',
    });


  function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };



const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const result = await apiCall(`${config.apiBaseUrl}/bug/report/submit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getCookie('csrf_access_token')  
      },
      body: JSON.stringify(formData),
      credentials: 'include'
    });

    console.log('Server response:', result);

    if (result.message) {
      console.log('Bug report submitted:', result);
      //alert('Bug report submitted successfully!');
      navigate('/bug/thankyou');

      // Reset form after submission
      setFormData({
        title: '',
        description: '',
        steps: '',
        expectedBehavior: '',
        actualBehavior: '',
        deviceInfo: '',
        email: '',
      });
    } else if (result.error) {
      throw new Error(result.error);
    } else {
      throw new Error('Unexpected server response');
    }
  } catch (error) {
    console.error('Error submitting bug report:', error.message);
    alert(`Failed to submit bug report: ${error.message}`);
  }
};


  return (
    <div className={styles.bugReportContainer}>
      <h1>Report a Bug</h1>
      <form onSubmit={handleSubmit} className={styles.bugReportForm}>
        <div className={styles.formGroup}>
          <label htmlFor="title">Bug Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="steps">Steps to Reproduce:</label>
          <textarea
            id="steps"
            name="steps"
            value={formData.steps}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="expectedBehavior">Expected Behavior:</label>
          <textarea
            id="expectedBehavior"
            name="expectedBehavior"
            value={formData.expectedBehavior}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="actualBehavior">Actual Behavior:</label>
          <textarea
            id="actualBehavior"
            name="actualBehavior"
            value={formData.actualBehavior}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="deviceInfo">Device Information:</label>
          <input
            type="text"
            id="deviceInfo"
            name="deviceInfo"
            value={formData.deviceInfo}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="email">Your Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" className={styles.submitButton}>Submit Bug Report</button>
      </form>
    </div>
  );
};

export default BugReportForm;