import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CommentModal.module.css';
import config from '../config';

const CommentModal = ({ 
  isOpen, 
  onClose, 
  comments, 
  onAddComment,
  onLoadMore, 
  hasMore, 
  isLoading 
}) => {
  const [newComment, setNewComment] = useState('');
  const loadMoreRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          onLoadMore();
        }
      },
      { threshold: 0.1 }
    );

    const currentElement = loadMoreRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [hasMore, isLoading, onLoadMore]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newComment.trim()) {
      onAddComment(newComment.trim());
      setNewComment('');
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <h3>Comments </h3>
          <button onClick={onClose} className={styles.closeButton}>×</button>
        </div>
        
        <div className={styles.commentsContainer}>
          {comments.map(comment => (
            <div 
              key={comment.id} 
              className={styles.commentItem}
            >
              <div className={styles.commentHeader}>
                <img 
                  src={`${config.mediaApiUrl}/${comment.user.avatar}`} 
                  alt={comment.user.username} 
                  className={styles.avatar}
                />
                <span className={styles.username}>{comment.user.username}</span>
              </div>
              <div className={styles.commentContent}>
                <p className={styles.commentText}>{comment.content}</p>
                <span className={styles.timestamp}>
                  {new Date(comment.created_at).toLocaleString()}
                </span>
              </div>
            </div>
          ))}
          
          {isLoading && (
            <div className={styles.loading}>Loading more comments...</div>
          )}
          
          {hasMore && <div ref={loadMoreRef} className={styles.loadMoreTrigger} />}
          
          {!hasMore && comments.length > 0 && (
            <div className={styles.endMessage}>No more comments to show</div>
          )}
        </div>

        {/* Comment Form */}
        <form onSubmit={handleSubmit} className={styles.commentForm}>
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            className={styles.commentInput}
            aria-label="Add a comment"
          />
          <button 
            type="submit" 
            className={styles.submitButton}
            disabled={!newComment.trim()}
          >
            Post
          </button>
        </form>
      </div>
    </div>
  );
};

CommentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      user: PropTypes.shape({
        username: PropTypes.string.isRequired,
        avatar: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  onAddComment: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CommentModal;