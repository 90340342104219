import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Post.module.css';
import ReportModal from '../components/ReportModal';
import PopupMenu from '../components/PopupMenu';
import { ReactComponent as CommentIcon } from '../assets/comment-regular.svg';
import { ReactComponent as HeartIcon } from '../assets/heart-regular.svg';
import { ReactComponent as ThreeDots } from '../assets/dots.svg';
import LikesModal from '../components/LikesModal';
import CommentModal from '../components/CommentModal';
import { useUserInfo } from '../hooks/userUserInfo';
import { apiCall } from '../utils/api';
import config from '../config';

const Post = ({ post, onReport, onSendDM, onBlock, currentUserId, onRemove }) => {
  // Basic state
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  // Likes state
  const [likesList, setLikesList] = useState([]);
  const [isLikesModalOpen, setIsLikesModalOpen] = useState(false);
  const [likesCurrentPage, setLikesCurrentPage] = useState(1);
  const [likesHasMore, setLikesHasMore] = useState(true);
  const [likesIsLoading, setLikesIsLoading] = useState(false);
  const [likesCount, setLikesCount] = useState(post.likes_count || 0);

  // Comments state
  const [commentsList, setCommentsList] = useState([]);
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [commentsCurrentPage, setCommentsCurrentPage] = useState(1);
  const [commentsHasMore, setCommentsHasMore] = useState(true);
  const [commentsIsLoading, setCommentsIsLoading] = useState(false);
  const [commentsCount, setCommentsCount] = useState(post.comments_count || 0);

  const popupRef = useRef(null);
  const threeDotsRef = useRef(null);
  const userInfo = useUserInfo();
  // Handle Likes Modal

  const handleLikesClick = async () => {
    if (likesIsLoading) return;
    setLikesIsLoading(true);
    setIsLikesModalOpen(true);
    setLikesList([]);
    setLikesCurrentPage(1);
    setLikesHasMore(true);
  
    try {
      const data = await apiCall(`${config.apiBaseUrl}/posts/likes/${post.id}?page=1&per_page=5`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (data && Array.isArray(data.items)) {
        setLikesList(data.items);
        setLikesCurrentPage(data.current_page);
        setLikesHasMore(data.current_page < data.pages);
      } else {
        console.error('Unexpected data structure:', data);
        setLikesList([]);
        setLikesHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching likes:', error);
      setLikesList([]);
      setLikesHasMore(false);
    } finally {
      setLikesIsLoading(false);
    }
  };
  
  const loadMoreLikes = async () => {
    
    if (likesIsLoading || !likesHasMore) return;
    
    setLikesIsLoading(true);
    try {
      const nextPage = likesCurrentPage + 1;
      
  
      const data = await apiCall(
        `${config.apiBaseUrl}/posts/likes/${post.id}?page=${nextPage}&per_page=5`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (data && Array.isArray(data.items)) {
        setLikesList(prev => [...prev, ...data.items]);
        setLikesCurrentPage(data.current_page);
        setLikesHasMore(data.current_page < data.pages);
      }
    } catch (error) {
      console.error('Error loading more likes:', error);
    } finally {
      setLikesIsLoading(false);
    }
  }; 


 const fetchComments = async (page = 1, append = false) => {
  if (commentsIsLoading || (!append && !commentsHasMore)) return;
  
  setCommentsIsLoading(true);
  
  try {
    const data = await apiCall(
      `${config.apiBaseUrl}/posts/${post.id}/comments/?page=${page}&per_page=20`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    );

    if (data) {
      setCommentsList(prev => append ? [...prev, ...data.items] : data.items);
      setCommentsCurrentPage(data.current_page);
      setCommentsHasMore(data.current_page < data.pages);
    }
  } catch (error) {
    console.error('Error fetching comments:', error);
    // Reset hasMore on error to prevent infinite loading attempts
    setCommentsHasMore(false);
  } finally {
    setCommentsIsLoading(false);
  }
};

const handleCommentClick = () => {
  setIsCommentsModalOpen(true);
  setCommentsList([]);
  setCommentsCurrentPage(1);
  setCommentsHasMore(true);
  fetchComments(1, false);
};

const loadMoreComments = () => {
  fetchComments(commentsCurrentPage + 1, true);
};

  // Like/Unlike post
  const handleLikeClick = async () => {
    try {
      const data = await apiCall(`${config.apiBaseUrl}/posts/${post.id}/like`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (data) {
        setLikesCount(data.count);
        setIsLiked(data.liked);
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  // Add new comment
  const handleAddComment = async (commentText, parentId = null) => {
    try {
      const commentData = {
        content: commentText,
        post_id: post.id,
        parent_id: parentId
      };

      if (!parentId) {
        delete commentData.parent_id;
      }

      const newComment = await apiCall(`${config.apiBaseUrl}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(commentData)
      });

      setCommentsList(prev => [...prev, newComment]);
      setCommentsCount(prev => prev + 1);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  // Handle comment like
  const handleLikeComment = async (commentId, isLiking) => {
    try {
      const response = await apiCall(`${config.apiBaseUrl}/comments/${commentId}/like`, {
        method: isLiking ? 'POST' : 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response) {
        setCommentsList(comments =>
          comments.map(comment =>
            comment.id === commentId
              ? { ...comment, likes: response.likes, isLiked: isLiking }
              : comment
          )
        );
      }
    } catch (error) {
      console.error('Error liking/unliking comment:', error);
    }
  };

  // Menu handlers
  const handleThreeDotsClick = (e) => {
    e.stopPropagation();
    setPopupOpen(!isPopupOpen);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleBlockToggle = () => {
    setIsBlocked(!isBlocked);
    onBlock(post.user.id);
    setPopupOpen(false);
  };

  const handleReportClick = () => {
    setIsReportModalOpen(true);
    setPopupOpen(false);
  };

  const handleReportSubmit = async (reportData) => {
    onReport(post.id, reportData);
    setIsReportModalOpen(false);
  };

  const handleRemoveClick = () => {
    setIsVisible(false);
    if (onRemove) {
      onRemove(post.id);
    }
  };

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) &&
          threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setPopupOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.postContainer}>
      <div className={styles.post}>
        <div className={styles.header}>
          <img 
            src={`${config.mediaApiUrl}/${post.user.avatar}`} 
            alt={`${post.user.username}'s avatar`} 
            className={styles.avatar} 
          />
          <span className={styles.username}>{post.user.username}</span>
          <div className={styles.threeDotsContainer} ref={threeDotsRef}>
            <ThreeDots className={styles.threeDots} onClick={handleThreeDotsClick} />
            <PopupMenu 
              isOpen={isPopupOpen}
              onClose={handleClosePopup}
              onReportClick={handleReportClick}
              username={post.user.username}
              userId={post.user.id}
            />
          </div>
        </div>

        <div className={styles.caption}>{post.caption}</div>

        {post.mediapath && (
          <div className={styles.imageContainer}>
            <img src={post.mediapath} alt="Post media" className={styles.image} />
            {currentUserId === post.user.id && (
              <button 
                className={styles.removeButton} 
                onClick={handleRemoveClick}
                aria-label="Remove image"
              >
                ×
              </button>
            )}
          </div>
        )}

        <div className={styles.icons}>
          {post.distance && (
            <div className={styles['distance-badge']}>
              {post.distance.toFixed(2)} miles away
            </div>
          )}
          
          <div className={styles.iconGroup}>
            <HeartIcon 
              className={`${styles.icon} ${isLiked ? styles.liked : ''}`} 
              onClick={handleLikeClick} 
            />
            <span className={styles.iconCount} onClick={handleLikesClick}>
              {likesCount}
            </span>
          </div>

          <div className={styles.iconGroup}>
            <CommentIcon 
              className={styles.icon} 
              onClick={handleCommentClick} 
            />
            <span className={styles.iconCount} onClick={handleCommentClick}>
              {commentsCount}
            </span>
          </div>
        </div>

        <div className={styles['timestamp-badge']}>
          Posted on: {new Date(post.datetimetimestamp).toLocaleString()}
        </div>

        <ReportModal
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onSubmit={handleReportSubmit}
          postId={post.id}
        />

      <LikesModal
        isOpen={isLikesModalOpen}
        onClose={() => setIsLikesModalOpen(false)}
        likes={likesList}
        onLoadMore={loadMoreLikes}
        hasMore={likesHasMore}
        isLoading={likesIsLoading}
      /> 

        <CommentModal
          isOpen={isCommentsModalOpen}
          onClose={() => {
    setIsCommentsModalOpen(false);
    setCommentsList([]); // Clear comments
    setCommentsCurrentPage(1); // Reset page number
    setCommentsHasMore(true); // Reset has more flag
  }}
          comments={commentsList}
          onAddComment={handleAddComment}
          onLikeComment={handleLikeComment}
          onLoadMore={loadMoreComments}
          hasMore={commentsHasMore}
          isLoading={commentsIsLoading}
        />
      </div>
    </div>
  );
};

Post.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    caption: PropTypes.string.isRequired,
    mediapath: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    ip: PropTypes.string,
    hashtags: PropTypes.string,
    datetimetimestamp: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      username: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
    }).isRequired,
    distance: PropTypes.number,
    likes_count: PropTypes.number.isRequired,
    comments_count: PropTypes.number.isRequired,
  }).isRequired,
  currentUserId: PropTypes.number,
  onSendDM: PropTypes.func,
  onReport: PropTypes.func,
  onBlock: PropTypes.func,
  onRemove: PropTypes.func,
};

Post.defaultProps = {
  currentUserId: null,
  onRemove: null,
};

export default Post;
