import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ReportModal.module.css';
import  config  from '../config'; 

const ReportModal = ({ isOpen, onClose, onSubmit, postId }) => {
  const [reason, setReason] = useState('');
  const [blockAuthor, setBlockAuthor] = useState(false);


const handleSubmit = async (e) => {

    e.preventDefault();

    const data = {
        postId: postId,
        reason: reason,
        blockAuthor: blockAuthor
    };


    const url = `${config.apiBaseUrl}/api/v1/report/${postId}`;
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data), // Added this line to send the data
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        //const result = await response.json();
        //console.log('Report submitted successfully:', result);

        // Uncomment these lines if you want to call these functions after successful submission
        // onSubmit({ postId, reason, blockAuthor });
        // onClose();
    }
     catch (error) {
        console.error('Error submitting report:', error);
        // Here you might want to show an error message to the user
    }
    onClose();
};

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>Report Post</h2>
        <form onSubmit={handleSubmit}>
          <textarea
            className={styles.reportTextarea}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Please provide a detailed reason for reporting this post..."
            required
          />
          <div className={styles.buttonGroup}>
            <button type="button" onClick={onClose} className={styles.cancelButton}>
              Cancel
            </button>
            <button type="submit" className={styles.submitButton}>
              Submit Report
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

ReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  postId: PropTypes.number.isRequired,
};

export default ReportModal;