import React, { useState, useCallback, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, ZoomControl, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import MultiRangeSlider from './MultiRangeSlider';
import styles from './NoPostsFoundSettings.module.css';
import { useUserInfo } from '../hooks/userUserInfo';
import feedIcon from '../components/navbar-images/peep.png';
import { useNavigate } from 'react-router-dom';

const customIcon = new Icon({
  iconUrl: feedIcon,
  iconSize: [38, 38],
  iconAnchor: [19, 38],
});

const DEFAULT_POSITION = [39.9526, -75.1652];
const DEFAULT_SETTINGS = {
  distance: { min: 0, max: 100 },
  ageRange: { min: 18, max: 100 },
  zoom: 6,
  position: DEFAULT_POSITION
};





const LocationMarker = ({ position, setPosition }) => {
  const map = useMapEvents({
    click(e) {
      setPosition(e.latlng);
    },
  });

  useEffect(() => {
    if (position) {
      map.panTo(position);
    }
  }, [map, position]);

  return position ? <Marker position={position} icon={customIcon} /> : null;
};

const MapLoader = ({ onLoad }) => {
  const map = useMap();
  useEffect(() => {
    onLoad(map);
  }, [map, onLoad]);
  return null;
};

const NoPostsFoundSettings = ({ onSettingsChange, onClose }) => {
  const userInfo = useUserInfo();
  const navigate = useNavigate();
  const modalRef = useRef(null);


 useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);
  

  const getStoredSettings = useCallback(() => {
    const storedSettings = JSON.parse(localStorage.getItem('feedSettings') || '{}');
    return {
      ...DEFAULT_SETTINGS,
      ...storedSettings,
      position: storedSettings.position || [parseFloat(userInfo.latitude) || DEFAULT_POSITION[0], parseFloat(userInfo.longitude) || DEFAULT_POSITION[1]]
    };
  }, [userInfo.latitude, userInfo.longitude]);

  const [feedSettings, setFeedSettings] = useState(getStoredSettings);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    localStorage.setItem('feedSettings', JSON.stringify(feedSettings));
  }, [feedSettings]);

  const handleSettingsChange = useCallback((key, value) => {
    setFeedSettings(prev => {
      const newSettings = { ...prev, [key]: value };
      localStorage.setItem('feedSettings', JSON.stringify(newSettings));
      return newSettings;
    });
  }, []);

  const handleDistanceChange = useCallback(({ min, max }) => {
    handleSettingsChange('distance', { min, max });
  }, [handleSettingsChange]);

  const handleAgeRangeChange = useCallback(({ min, max }) => {
    handleSettingsChange('ageRange', { min, max });
  }, [handleSettingsChange]);

  const handleApply = useCallback(() => {
    onSettingsChange(feedSettings);
    if (typeof onClose === 'function') {
      onClose();
    }
    navigate('/feed');
  }, [feedSettings, onSettingsChange, onClose, navigate]);

  const handleMapLoad = useCallback((map) => {
    setMapLoaded(true);
    map.on('zoomend', () => {
      handleSettingsChange('zoom', map.getZoom());
    });
  }, [handleSettingsChange]);

  return (
    <div className={styles.settingsContainer} ref={modalRef}>
      <h2>No Posts Found</h2>
      <p>Adjust your settings to find more posts:</p>
      
      <div className={styles.settingItem}>
        <label>Preferred Distance (miles)</label>
        <MultiRangeSlider
          min={0}
          max={100}
          initialMin={feedSettings.distance.min}
          initialMax={feedSettings.distance.max}
          onChange={handleDistanceChange}
        />
        <span className={styles.rangeDisplay}>{feedSettings.distance.min} - {feedSettings.distance.max}</span>
      </div>
      
      <div className={styles.settingItem}>
        <label>Preferred Age Range (years)</label>
        <MultiRangeSlider
          min={18}
          max={100}
          initialMin={feedSettings.ageRange.min}
          initialMax={feedSettings.ageRange.max}
          onChange={handleAgeRangeChange}
        />
        <span className={styles.rangeDisplay}>{feedSettings.ageRange.min} - {feedSettings.ageRange.max}</span>
      </div>
      
      <div className={styles.settingItem}>
        <label>Select Your Location</label>
        <MapContainer center={feedSettings.position} zoom={feedSettings.zoom} style={{ height: '240px', width: '100%' }} zoomControl={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
          />
          {mapLoaded && (
            <LocationMarker 
              position={feedSettings.position} 
              setPosition={(newPosition) => handleSettingsChange('position', newPosition)}
            />
          )}
          
          <MapLoader onLoad={handleMapLoad} />
        </MapContainer>
      </div>
      
      <div className={styles.buttonContainer}>
        <button onClick={handleApply} className={styles.applyButton}>
          apply
        </button>
      </div>
    </div>
  );
};

export default NoPostsFoundSettings;