// RegisterPrompt.js
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './RegisterPrompt.module.css';

const RegisterPromptContainer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>Join peepnest today!</h2>
        <p className={styles.description}>Sign up now to be part of our growing community.</p>
        <div className={styles.buttonContainer}>
          <Link 
            to="/register" 
            className={styles.primaryButton}
          >
            Register Now
          </Link>
          <Link 
            to="/login" 
            className={styles.secondaryButton}
          >
            login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterPromptContainer;