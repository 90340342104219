import React from 'react';
import './PrivacyPolicy.module.css'; 

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date</strong>: 09/29/2024</p>

      <p>
        At <strong>peepnest</strong> we are committed to protecting the privacy of your personal information. 
        This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our social media platform, 
        available globally, with features that are geofenced to specific locations. By using our platform, you consent to the data practices 
        described in this policy. If you do not agree with this policy, please do not use our application.
      </p>

      <h2>1. Information We Collect</h2>
      <p>We collect the following types of information:</p>

      <h3>a. Personal Information</h3>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Username or other identifying information</li>
        <li>Location data (with your consent)</li>
        <li>Profile information (such as profile picture, bio, etc.)</li>
      </ul>

      <h3>b. Location Data</h3>
      <p>
        Given the geofenced nature of our application, we collect precise location data from your device with your explicit consent. 
        This data is critical for providing location-based features. You can control access to your location through your device settings.
      </p>

      <h3>c. Automatically Collected Information</h3>
      <ul>
        <li>IP address</li>
        <li>Device type and operating system</li>
        <li>Browser type</li>
        <li>Usage statistics (including features used and time spent on the platform) etc.</li>
      </ul>

      <h3>d. User-Generated Content</h3>
      <p>
        Any content you post on our platform, including photos, videos, comments, and other submissions, may be collected and stored.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>We use your information for the following purposes:</p>
      <ul>
        <li>To provide, maintain, and improve our services</li>
        <li>To personalize your experience based on location and preferences</li>
        <li>To communicate with you about your account and our services</li>
        <li>To comply with legal obligations or respond to lawful requests</li>
        <li>To protect the security and integrity of our platform</li>
        <li>For analytics and research purposes to improve our platform</li>
      </ul>

      <h2>3. How We Share Your Information</h2>
      <p>We may share your personal information in the following situations:</p>
      <ul>
        <li><strong>With Your Consent</strong>: We may share your information with third parties when you provide explicit consent.</li>
        <li><strong>Service Providers</strong>: We may share your data with third-party vendors that help us provide our services (e.g., hosting providers, analytics services).</li>
        <li><strong>Legal Requirements</strong>: We may disclose your information to comply with legal obligations, court orders, or government requests.</li>
        <li><strong>Business Transfers</strong>: In the event of a merger, acquisition, or sale of assets, your data may be transferred to another entity.</li>
      </ul>

      <h2>4. International Data Transfers</h2>
      <p>
        Your information may be processed in countries other than your own, where data protection laws may be different from those in your jurisdiction. 
        We will ensure that appropriate safeguards are in place when transferring data internationally, such as standard contractual clauses approved by relevant authorities.
      </p>

      <h2>5. Data Retention</h2>
      <p>
        We retain your personal information for as long as necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required by law.
      </p>

      <h2>6. Security of Your Information</h2>
      <p>
        We take reasonable steps to protect your personal information from unauthorized access, use, disclosure, or destruction. However, no method of transmission over 
        the internet is 100% secure, and we cannot guarantee the absolute security of your data.
      </p>

      <h2>7. Your Rights and Choices</h2>
      <p>Depending on your location and the applicable laws, you may have the following rights:</p>
      <ul>
        <li><strong>Access</strong>: You can request access to the personal information we hold about you.</li>
        <li><strong>Correction</strong>: You can request corrections to your personal information if it is inaccurate or incomplete.</li>
        <li><strong>Deletion</strong>: You may request the deletion of your personal information in certain circumstances.</li>
        <li><strong>Opt-Out</strong>: You can opt out of receiving promotional communications by following the instructions in those communications or by contacting us directly.</li>
      </ul>
      <p>To exercise any of these rights, please contact us at [Insert Contact Information].</p>

      <h2>8. Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar tracking technologies to collect information about your interactions with our platform. 
        You can manage your cookie preferences through your browser settings.
      </p>

      <h2>9. Children's Privacy</h2>
      <p>
        Our platform is not intended for users under the age of 16. We do not knowingly collect personal information from children under 16. 
        If we learn that we have collected such data, we will take steps to delete it.
      </p>

      <h2>10. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. When we do, we will post the revised policy on our platform and update the “Effective Date” above. 
        Your continued use of our platform after the changes means that you accept the updated policy.
      </p>

      <h2>11. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at:
      </p>
      <p>Email: tom@peeps.sh</p>
    </div>
  );
};

export default PrivacyPolicy;
