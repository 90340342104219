import { useSelector } from 'react-redux';

export const useUserInfo = () => {
  const user = useSelector((state) => state.user);
  
  return {
    id: user?.id,
    username: user?.username,
    avatarUrl: user?.avatarUrl,
    token: user?.token,
    latitude: user?.latitude,
    longitude: user?.longitude,
  };
};