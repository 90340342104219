import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/userSlice';
import config from '../config';
import styles from './Login.module.css';
import Loading from './Loading';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [shakeError, setShakeError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getLocationCoordinates = () => {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          () => resolve({ latitude: 0, longitude: 0 })
        );
      } else {
        console.error('Geolocation not supported');
        resolve({ latitude: 0, longitude: 0 });
      }
    });
  };
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const { latitude, longitude } = await getLocationCoordinates();
      const credentials = { username, password, coords: { latitude, longitude } };

      const response = await fetch(`${config.apiBaseUrl}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials),
        credentials: 'include',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Login failed');
      }

      const data = await response.json();
      const avatarUrl = `${config.mediaApiUrl}/${data.avatar}`;

      dispatch(setUser({
        id: data.user_id,
        username: data.username,
        avatarUrl,
        latitude,
        longitude,
        isLoggedIn: true,
      }));

      const feedSettings = {
        position: { lat: latitude, lng: longitude },
        distance: { min: 1, max: 10 },
        ageRange: { min: 18, max: 100 }
      };
      
      localStorage.setItem('feedSettings', JSON.stringify(feedSettings));
      
      // Set a timeout to show the loading animation for 2 seconds
      setTimeout(() => {
        setIsLoading(false);
        navigate('/feed');
      }, 500);
    } catch (error) {
      setError(error.message || 'Incorrect credentials. Please try again.');
      setShakeError(true);
      setTimeout(() => setShakeError(false), 1000);
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className={styles.loginContainer}>
      <h2>Login</h2>
      {error && (
        <div className={`${styles.errorBubble} ${shakeError ? styles.shakeAnimation : ''}`}>
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit} className={styles.loginForm}>
        <div className={styles.inputGroup}>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className={styles.inputField}
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={styles.inputField}
          />
        </div>
        <button type="submit" className={styles.submitButton}>
          Login
        </button>
      </form>
    </div>
  );
};
export default Login;