import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import io from 'socket.io-client';
import { apiCall } from '../utils/api';
import { useNavigate } from 'react-router-dom';
import  config  from '../config';




const API_BASE_URL = `${config.apiBaseUrl}`;
// Only diff is API_BASE_URL has /api/ and WEBSOCKET_BASE_URL does not have /api/ prefix.
const WEBSOCKET_BASE_URL = `${config.websocketApiUrl}`;

const NotificationCenter = (onClose) => {
  const [activeTab, setActiveTab] = useState('notifications');
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const observer = useRef();
  const lastItemElementRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const csrfToken = getCookie('csrf_access_token');


  const handleNavigateToMessages = (otherUserId, otherUsername, otherAvatar) => {
    navigate(`/messages/${otherUserId}`, { state: { username: otherUsername, userId: otherUserId, avatar: otherAvatar } });
    if (typeof onClose === 'function') {
      onClose();
    } else {
      console.warn('onClose prop is not a function or not provided');
    }
  };



  const fetchItems = useCallback(async (resetPage = false) => {
    if (isLoading) return;
    setIsLoading(true);
    const currentPage = resetPage ? 1 : page;
    try {
      const endpoint = activeTab === 'notifications' ? '/notifications/' : '/messages/';
      const response = await fetch(`${API_BASE_URL}${endpoint}?page=${currentPage}&per_page=7`, {
        method: 'GET',
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      const newItems = data.items;
      setItems(prev => {
        if (resetPage) {
          return newItems;
        }
        const uniqueNewItems = newItems.filter(newItem => !prev.some(item => item.id === newItem.id));
        return [...prev, ...uniqueNewItems];
      });
      setHasMore(newItems.length === 7);
      if (resetPage) {
        setPage(1);
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setIsLoading(false);
    }
  }, [page, activeTab, isLoading, csrfToken]);

  useEffect(() => {
    fetchItems(true);
  }, [activeTab]);

  useEffect(() => {
    if (page > 1) {
      fetchItems(false);
    }
  }, [page]);

  useEffect(() => {
    const socket = io(WEBSOCKET_BASE_URL, {
      withCredentials: true,
      transports: ['websocket', 'polling'],
      path: '/api/websocket'
    });
    
    socket.on('connect', () => {
      console.log('Connected to WebSocket');
    });

    socket.on('new_notification', (notification) => {
      setItems(prev => [{ ...notification, type: 'notification' }, ...prev]);
    });

    socket.on('new_message', (message) => {
      setItems(prev => [{ ...message, type: 'dm' }, ...prev]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const markAsRead = async (itemId, itemType) => {
    try {
      const endpoint = itemType === 'notification' ? '/notifications' : '/messages';
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'PUT',
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ item_id: itemId }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setItems(prev => 
        prev.map(item => 
          item.id === itemId ? { ...item, is_read: true } : item
        )
      );
    } catch (error) {
      console.error('Error marking item as read:', error);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setPage(1);
    setHasMore(true);
    setItems([]);
  };


  const renderItem = (item, index) => {
    
    const isLastItem = index === items.length - 1;
    if (item.type === 'notification') {
      return (
        <NotificationItem 
          key={item.id} 
          onClick={() => markAsRead(item.id, 'notification')}
          isRead={item.is_read}
          ref={isLastItem ? lastItemElementRef : null}
        >
          <AvatarContainer>
            {item.action_user_avatar ? (
              
              <AvatarImage src={`${config.mediaApiUrl}/${item.action_user_avatar}`} alt={item.action_user_name} />
            ) : (
              <FallbackAvatar>{item.action_user_name[0].toUpperCase()}</FallbackAvatar>
            )}
          </AvatarContainer>
          <NotificationContent>
            <NotificationText>
              <strong>{item.action_user_name}</strong> {item.notification_type === 'like' ? 'liked' : 'commented on'} your post
            </NotificationText>
            <NotificationTime>{new Date(item.created_at).toLocaleString()}</NotificationTime>
          </NotificationContent>
        </NotificationItem>
      );
    } else if (item.type === 'dm') {
      return (
        <MessageItem 
          key={item.id} 
          onClick={() => {
          markAsRead(item.id, 'dm');
          handleNavigateToMessages(item.sender_id, item.sender_name, item.sender_avatar);
        }}
          isRead={item.is_read}
          ref={isLastItem ? lastItemElementRef : null}
        >
          <AvatarContainer>
            {item.sender_avatar ? (
              <AvatarImage src={`${config.mediaApiUrl}/${item.sender_avatar}`} alt={item.sender_name} />
            ) : (
              <FallbackAvatar>{item.sender_name[0].toUpperCase()}</FallbackAvatar>
            )}
          </AvatarContainer>
          <MessageContent>
            <MessageUser>{item.sender_name}</MessageUser>
            <MessagePreview>{item.content}</MessagePreview>
            <MessageTime>{new Date(item.created_at).toLocaleString()}</MessageTime>
          </MessageContent>
        </MessageItem>
      );
    }
  };

  return (
    <Container>
      <Header>
        <Title>Notification Center</Title>
        <TabContainer>
          <Tab 
            active={activeTab === 'notifications'} 
            onClick={() => handleTabClick('notifications')}
          >
            Notifications
          </Tab>
          <Tab 
            active={activeTab === 'dms'} 
            onClick={() => handleTabClick('dms')}
          >
            DMs
          </Tab>
        </TabContainer>
      </Header>
      <Content>
        <ItemList>
          {items.map((item, index) => renderItem(item, index))}
          {isLoading && <LoadingIndicator>Loading more...</LoadingIndicator>}
        </ItemList>
      </Content>
    </Container>
  );
};



const Container = styled.div`
  background-color: #000000;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }

  @media (min-width: 769px) {
    width: 800px;
    height: 80vh;
    max-height: 800px;
    margin: 20px auto;
    border-radius: 12px;
    overflow: hidden;
  }
`;

const Header = styled.div`
  background-color: #121212;
  padding: 20px;
  border-bottom: 1px solid #333;
`;

const Title = styled.h1`
  color: #fed057;
  margin: 0 0 15px 0;
  font-size: 24px;
  font-weight: 600;
`;

const TabContainer = styled.div`
  display: flex;
  background-color: #1a1a1a;
  border-radius: 8px;
  overflow: hidden;
`;

const Tab = styled.button`
  flex: 1;
  padding: 12px;
  background-color: ${props => props.active ? '#fed057' : 'transparent'};
  color: ${props => props.active ? '#000000' : '#ffffff'};
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.active ? '#fed057' : 'rgba(254, 208, 87, 0.1)'};
  }
`;

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1a1a;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fed057;
    border-radius: 4px;
    border: 2px solid #1a1a1a;
  }

  scrollbar-width: thin;
  scrollbar-color: #fed057 #1a1a1a;
`;

const ItemList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const NotificationItem = styled.li`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #333;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #1a1a1a;
  }
`;

const MessageItem = styled(NotificationItem)``;

const AvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FallbackAvatar = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fed057;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
`;

const NotificationContent = styled.div`
  flex: 1;
`;

const MessageContent = styled(NotificationContent)``;

const NotificationText = styled.p`
  color: #ffffff;
  margin: 0 0 5px 0;
  font-size: 14px;
  line-height: 1.4;
`;

const NotificationTime = styled.span`
  color: #999999;
  font-size: 12px;
`;

const MessageUser = styled.p`
  color: #ffffff;
  margin: 0 0 5px 0;
  font-weight: 600;
  font-size: 14px;
`;

const MessagePreview = styled.p`
  color: #cccccc;
  margin: 0 0 5px 0;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MessageTime = styled(NotificationTime)``;

const LoadingIndicator = styled.div`
  text-align: center;
  padding: 15px;
  font-style: italic;
  color: #fed057;
  font-size: 14px;
`;

export default NotificationCenter;
