import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearUser } from '../store/userSlice'; // Adjust the import path as needed
import config from '../config';
import { persistor } from '../store'; // Import the persistor

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }


  useEffect(() => {
    const performLogout = async () => {
      try {
        const response = await fetch(`${config.apiBaseUrl}/logout`, {
          method: 'POST',
          headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token')  // Get the CSRF token from the cookie

          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Logout failed');
        }

        // Clear Redux state
        dispatch(clearUser());

        // Clear persisted Redux state
        await persistor.purge();

        // Clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();

        // Clear cookies
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });

        // Navigate to login page
        navigate('/login');
      } catch (error) {
        console.error('Error logging out:', error);
        // Even if the server-side logout fails, clear client-side data
        dispatch(clearUser());
        await persistor.purge();
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
      }
    };

    performLogout();
  }, [dispatch, navigate]);

  return <div>Logging out...</div>;
};

export default Logout;