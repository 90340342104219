import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './RegisterPage.module.css';
import feedIcon from '../components/navbar-images/peep.png';
import  config  from '../config'; 

const RegisterPage = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    phonenumber: '',
    email: '',
    bio: '',
    city: '',
    state: '',
  });
  const [avatar, setAvatar] = useState(null);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleFileSelect = (event) => {
    setAvatar(event.target.files[0]);
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  setError('');

  if (!userData.username || !userData.password || !userData.confirmPassword || !userData.phonenumber || !userData.email || !userData.bio || !userData.city || !userData.state || !userData.age) {
    setError('All fields are required');
    return;
  }

  if (userData.password !== userData.confirmPassword) {
    setError("Passwords don't match");
    return;
  }

  if (!avatar) {
    setError('Please select an avatar');
    return;
  }

  const formData = new FormData();
  formData.append('username', userData.username);
  formData.append('password', userData.password);
  formData.append('phonenumber', userData.phonenumber);
  formData.append('email', userData.email);
  formData.append('bio', userData.bio);
  formData.append('city', userData.city);
  formData.append('state', userData.state);
  formData.append('age', userData.age);
  formData.append('avatar', avatar);

  try {
    const response = await fetch(`${config.apiBaseUrl}/users/register`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.message.includes("Duplicate entry")) {
        setError("Username already exists. Please choose another username!");
      } else {
        setError('Registration failed');
      }
      return;
    }

    const result = await response.json();
    console.log('Registration successful:', result);
    navigate('/auth/otp');
  } catch (error) {
    console.error('Error during registration:', error);
    setError('Registration failed. Please try again.');
  }
};

  const inputFields = [
    { id: 'username', type: 'text', placeholder: 'Enter your username' },
    { id: 'password', type: 'password', placeholder: 'Enter your password' },
    { id: 'confirmPassword', type: 'password', placeholder: 'Confirm your password' },
    { id: 'age', type: 'number', placeholder: 'Enter your age' },
    { id: 'phonenumber', type: 'phonenumber', placeholder: 'Enter your phone number' },
    { id: 'email', type: 'email', placeholder: 'Enter your email' },
    { id: 'bio', type: 'text', placeholder: 'Enter your bio' },
    { id: 'city', type: 'text', placeholder: 'Enter your city' },
    { id: 'state', type: 'text', placeholder: 'Enter your state' },
  ];

  return (
    <div className={styles.registerContainer}>
      <img src={feedIcon} alt="Feed Icon" className={styles.feedIcon} />
      <h2>Register for peepnest today!</h2>
      {error && <p className={styles.error}>{error}</p>}
      <form onSubmit={handleSubmit} className={styles.registerForm}>
        {inputFields.map((field) => (
          <div key={field.id} className={styles.inputGroup}>
            <label htmlFor={field.id}>{field.id.charAt(0).toUpperCase() + field.id.slice(1)}</label>
            <input
              type={field.type}
              id={field.id}
              placeholder={field.placeholder}
              value={userData[field.id]}
              onChange={handleInputChange}
              required
              className={styles.inputField}
            />
          </div>
        ))}
        <div className={styles.inputGroup}>
          <input
            type="file"
            id="avatar"
            accept="image/*"
            onChange={handleFileSelect}
            className={styles.hiddenFileInput}
          />
          <label htmlFor="avatar" className={styles.fileInputLabel}>
            Choose Profile Picture
          </label>
          {avatar && <span className={styles.fileName}>{avatar.name}</span>}
        </div>
        <button type="submit" className={styles.submitButton}>
          Register
        </button>
      </form>
      <p className={styles.loginLink}>
        Already have an account? <Link to="/login">Login here</Link>
      </p>
    </div>
  );
};

export default RegisterPage;
