import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './OTPPage.module.css'; // Import the stylesheet

const VerificationCodeInput = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Send the verification code to your backend server to verify it with Twilio Verify
    fetch('http://localhost:8000/auth/twillio/otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ otp: verificationCode }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'OTP is valid!') {
          setSuccess('Verification successful!');
          navigate('/login');
          setError(null);
        } else {
          setError('Verification failed!');
          setSuccess(null);
        }
      })
      .catch((error) => {
        setError('Error: ' + error.message);
        setSuccess(null);
      });
  };

  return (
    <div className={styles.verificationPage}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>Verify Your Account</h1>
          <p>Enter the verification code sent to your phone</p>
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.inputGroup}>
            <label>Verification Code:</label>
            <input
              type="number"
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              className={styles.inputField}
            />
          </div>
          <button type="submit" className={styles.submitButton}>
            Verify
          </button>
          {error && <p className={styles.errorMessage}>{error}</p>}
          {success && <p className={styles.successMessage}>{success}</p>}
        </form>
      </div>
    </div>
  );
};

export default VerificationCodeInput;