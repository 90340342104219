import { useEffect, useRef } from 'react';
import { refreshTokens } from '../services/auth';

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

export const useTokenRefresh = () => {
  const refreshInterval = useRef(null);
  const isLoginPage = window.location.pathname === '/login';

  useEffect(() => {
    if (isLoginPage) {
      return;
    }

    const refreshToken = async () => {
      
      
      const success = await refreshTokens();
      
      
      if (!success) {
        
        if (refreshInterval.current) {
          clearInterval(refreshInterval.current);
          refreshInterval.current = null;
        }
      }
    };

    
    if (!refreshInterval.current) {
      // Use shorter interval for testing
      refreshInterval.current = setInterval(refreshToken, 5*60*1000); // 30 seconds
      refreshToken(); // Initial refresh
    }

    return () => {
      
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
        refreshInterval.current = null;
      }
    };
  }, [isLoginPage]);
};