import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./multiRangeSlider.module.css";


const MultiRangeSlider = ({ min, max, initialMin, initialMax, onChange }) => {
  const [minVal, setMinVal] = useState(initialMin || min);
  const [maxVal, setMaxVal] = useState(initialMax || max);
  const minValRef = useRef(initialMin || min);
  const maxValRef = useRef(initialMax || max);
  const range = useRef(null);



  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);
    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);
    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Generate tick marks
  const generateTicks = () => {
    const ticks = [];
    for (let i = min; i <= max; i += 10) {
      ticks.push(
        <div
          key={i}
          className={styles.tick}
          style={{ left: `${getPercent(i)}%` }}
        >
          <span className={styles.tickLabel}>{i}</span>
        </div>
      );
    }
    return ticks;
  };
    return (
    <div className={styles.sliderContainer}>
      <div className={styles.sliderWrapper}>
        <div className={styles.slider}>
          <div className={styles.slider__track} />
          <div ref={range} className={styles.slider__range} />
          <div className={styles.slider__ticks}>{generateTicks()}</div>
        </div>
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onChange={(event) => {
            const value = Math.min(Number(event.target.value), maxVal - 1);
            setMinVal(value);
            minValRef.current = value;
            onChange({ min: value, max: maxVal });
          }}
          className={`${styles.thumb} ${styles["thumb--left"]}`}
          style={{ zIndex: minVal > max - 100 ? 5 : undefined }}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onChange={(event) => {
            const value = Math.max(Number(event.target.value), minVal + 1);
            setMaxVal(value);
            maxValRef.current = value;
            onChange({ min: minVal, max: value });
          }}
          className={`${styles.thumb} ${styles["thumb--right"]}`}
        />
      </div>
      <div className={styles.sliderValues}>
        <div className={styles["slider__left-value"]}>{minVal}</div>
        <div className={styles["slider__right-value"]}>{maxVal}</div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  initialMin: PropTypes.number,
  initialMax: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default MultiRangeSlider;