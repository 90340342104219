import  config  from '../config';  // Adjust the path as needed

async function refreshToken() {
    try {
        const response = await fetch(`${config.apiBaseUrl}/auth/refresh-token/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_refresh_token')
            }
        });
        return response.ok;
    } catch (error) {
        console.error('Error refreshing token:', error);
        return false;
    }
}

export async function apiCall(url, options = {}) {
    const makeRequest = async () => {
        const response = await fetch(url, {
            ...options,
            credentials: 'include',
            headers: {
                ...options.headers,
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    };

    try {
        return await makeRequest();
    } catch (error) {
        if (error.message.includes('status: 401')) {
            const refreshSuccess = await refreshToken();
            if (refreshSuccess) {
                // Try the request again with the new token
                return await makeRequest();
            } else {
                throw new Error('Session expired. Please log in again.');
            }
        }
        throw error;
    }
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}
