
import config from '../config';

export const refreshTokens = async () => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/auth/refresh-token/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getCookie('csrf_refresh_token')
      }
    });
    
    return response.ok;
  } catch (error) {
    console.error('Error refreshing token:', error);
    return false;
  }
};

// Helper function to get cookie
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}