// config.js
/*
Run npm start to start your development server with the development configuration.
Run npm run build to create a production build.
Run npm run build:dev to create a development build (if needed).
*/
const devConfig = {
  apiBaseUrl: 'http://localhost:8000/api',
  mediaApiUrl: 'https://media.peeps.sh',
  websocketApiUrl: 'http://localhost:8000'
};

const prodConfig = {
  apiBaseUrl: 'https://peepnest.com/api',
  mediaApiUrl: 'https://media.peepnest.com',
  websocketApiUrl: 'https://peepnest.com'
};

const config = process.env.REACT_APP_ENV === 'production' ? prodConfig : devConfig;

export default config;
