import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './PopupMenu.module.css';

const PopupMenu = ({ isOpen, onClose, onReportClick, username, userId }) => {
  const popupRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleNavigateToMessages = () => {
    navigate(`/messages/${userId}`, { state: { username, userId } });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.container}>
      <div className={styles.overlay} />
      <div className={styles.popupMenu} ref={popupRef}>
        <div className={styles.controlPanel}>
          <div className={styles.buttonGroup}>
            <button onClick={handleNavigateToMessages} className={styles.largeButton}>
              Send DM to {username}
            </button>
            <button onClick={onReportClick} className={styles.largeButton}>
              Report Post
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupMenu;