// MessagePage.js
import React, { useState, useEffect } from 'react';
import Messages from '../components/Messages';

const MessagePage = () => {
  // Assuming conversationId and userId are obtained somehow
  const conversationId = 'conversation_id'; // Replace with actual conversation ID
  const userId = 'user_id'; // Replace with actual user ID
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="message-page">
      <Messages conversationId={conversationId} userId={userId} />
    </div>
  );
};

export default MessagePage;
