import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider, useSelector } from 'react-redux';
import { store, persistor } from './store';
import Navbar from './components/Navbar';
import Privacy from './pages/PrivacyPolicyPage'
import Login from './components/Login';
import RegisterPage from './pages/RegisterPage';
import OTPPage from './pages/OTPPage';
import FeedPage from './pages/FeedPage';
import MessagePage from './pages/MessagePage';
import SettingsPage from './pages/SettingsPage';
import MapPage from './pages/MapPage';
import Messages from './components/Messages';
import NotificationCenter from './pages/NotificationCenter';
import styles from './App.module.css';
import BugReport from './pages/BugReport';
import ThankYouBugReportPage from './pages/ThankYouBugReportPage';
import Logout from './components/Logout';
import ProtectedRoute from './components/ProtectedRoute';
import { useTokenRefresh } from './hooks/useTokenRefresh';

const App = () => {
  useTokenRefresh();
  return (
    <Provider store={store}>
      <PersistGate
        loading={<div>Loading...</div>}
        persistor={persistor}
        onBeforeLift={() => {}}
      >
        <AppContent />
      </PersistGate>
    </Provider>
  );
};

const AppContent = () => {
  const user = useSelector(state => state.user);
  
  React.useEffect(() => {
    // Any effects that depend on user state
  }, [user]);

  return (
    <Router>
      <div className={styles.app}>
        <main className={styles.content}>
          <Routes>
            <Route path="/" element={<FeedPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/auth/otp" element={<OTPPage />} />
            <Route path="/map" element={<MapPage />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/feed" element={<FeedPage />} />
            <Route path="/privacy" element={<Privacy />} />             
            <Route path="/bug" element={<BugReport />} />
            <Route path="/bug/thankyou" element={<ThankYouBugReportPage />} />
            <Route path="/messages" element={
              <ProtectedRoute>
                <NotificationCenter />
              </ProtectedRoute>
            } />
            <Route path="/messages/:userId" element={
              <ProtectedRoute>
                <Messages />
              </ProtectedRoute>
            } />
            <Route path="/messages/:conversationId" element={
              <ProtectedRoute>
                <MessagePage />
              </ProtectedRoute>
            } />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/post/new" element={<div>New Post Component</div>} />
          </Routes>
        </main>
        <Navbar />
      </div>
    </Router>
  );
};

export default App;