import React, { useState } from 'react';
import { useUserInfo } from '../hooks/userUserInfo'; // Adjust the path as needed
import styles from './NewPostForm.module.css';
import  config  from '../config'; 
import Loading from './Loading';

const NewPostForm = ({ onClose }) => {
    const [content, setContent] = useState('');
    const [media, setMedia] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [hashtags, setHashtags] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    // Use custom hook to get user info
    const userInfo = useUserInfo();

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }


    const getLocationCoordinates = () => {
        return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                resolve([latitude, longitude]);
            },
            (error) => {
                console.error('Geolocation error:', error);
                resolve([0, 0]);
            }
            );
        } else {
            console.error('Geolocation not supported');
            resolve([0, 0]);
        }
        });
    };

    const handleMediaChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setMedia(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Start loading state

        try {
            const [freshLatitude, freshLongitude] = await getLocationCoordinates();
            const formData = new FormData();
            formData.append('caption', content);
            formData.append('hashtags', hashtags);
            formData.append('longitude', freshLongitude);
            formData.append('latitude', freshLatitude);
            if (media) {
                formData.append('media', media);
            }
            
            const response = await fetch(`${config.apiBaseUrl}/posts`, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': getCookie('csrf_access_token')
                },
                body: formData,
                credentials: 'include'
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to create post: ${response.status}. Server says: ${errorText}`);
            }
            
            await response.json();
            
            // Add a delay before closing to show the loading state
            setTimeout(() => {
                setIsSubmitting(false);
                onClose();
            }, 2000);

        } catch (error) {
            console.error('Error:', error.message);
            setIsSubmitting(false);
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    // Show Loading component when submitting
    if (isSubmitting) {
        return <Loading message="Creating new post!"/>;
    }

    return (
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
            <form className={styles.newPostForm} onSubmit={handleSubmit}>
                <div className={styles.header}>
                    <img src={userInfo.avatarUrl} alt={userInfo.username} className={styles.avatar} />
                    <span className={styles.username}>{userInfo.username}</span>
                </div>
                <textarea
                    className={styles.contentInput}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder="What's on your mind?"
                />
                <div className={styles.fileInputWrapper}>
                    <label className={styles.fileInputButton}>
                        Upload
                        <input
                            type="file"
                            onChange={handleMediaChange}
                            className={styles.fileInput}
                            accept="image/*"
                        />
                    </label>
                </div>
                {previewUrl && (
                    <div className={styles.imagePreview}>
                        <img src={previewUrl} alt="Preview" />
                    </div>
                )}
                <input
                    type="text"
                    value={hashtags}
                    onChange={(e) => setHashtags(e.target.value)}
                    placeholder="Add hashtags"
                    className={styles.hashtagInput}
                />
                <button type="submit" className={styles.submitButton}>Post</button>
            </form>
        </div>
    );
};
export default NewPostForm;