import React, { useState, useEffect, useCallback, useRef } from 'react';
import Post from '../components/Post';
import styles from './FeedPage.module.css';
import NewPostContainer from '../components/NewPostContainer';
import Modal from '../components/Modal';
import NewPostForm from '../components/NewPostForm';
import { useUserInfo } from '../hooks/userUserInfo';
import NoPostsFoundSettings from '../components/NoPostsFoundSettings';
import config from '../config';
import Loading from '../components/Loading';
import RegisterPromptContainer from '../components/RegisterPrompt';
import { useSelector } from 'react-redux';

import { Loader } from 'lucide-react';
const POSTS_PER_PAGE = 5;

const FeedPage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [showSettings, setShowSettings] = useState(false);
  const [locationPrompted, setLocationPrompted] = useState(false);
  const userInfo = useUserInfo();
  const loadingRef = useRef(null);

  const getLocationCoordinates = () => {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ lat: latitude, lng: longitude });
          },
          () => {
            console.error('Geolocation error or permission denied');
            resolve({ lat: 0, lng: 0 });
          }
        );
      } else {
        console.error('Geolocation not supported');
        resolve({ lat: 0, lng: 0 });
      }
    });
  };

  const initializeFeedSettings = useCallback(async () => {
    let feedSettings = JSON.parse(localStorage.getItem('feedSettings'));
    if (!feedSettings || !feedSettings.position || !feedSettings.position.lat || !feedSettings.position.lng) {
      const location = await getLocationCoordinates();
    
      feedSettings = {
        position: location,
        distance: { min: 1, max: 10 },
        ageRange: { min: 18, max: 100 }
      };
      localStorage.setItem('feedSettings', JSON.stringify(feedSettings));
    }
    setLocationPrompted(true);
    return feedSettings;
  }, []);
  const fetchPosts = useCallback(async (newSettings) => {
  if (loading || !hasMore) return;

  setLoading(true);
  setError(null);

  try {
    let feedSettings = newSettings || await initializeFeedSettings();
    const { lat, lng } = feedSettings.position;
    const dist = feedSettings.distance.max;
    const minAge = feedSettings.ageRange.min;
    const maxAge = feedSettings.ageRange.max;

    // Check for invalid coordinates before making the API call
    if (lat === 0 && lng === 0) {
      throw new Error('Invalid coordinates. Please update your location.');
    }
    let url = `${config.apiBaseUrl}/posts/distance/age/?lat=${lat}&lon=${lng}&dist=${dist}&min_age=${minAge}&max_age=${maxAge}&limit=${POSTS_PER_PAGE}&offset=${page * POSTS_PER_PAGE}`;
    
    if (userInfo && userInfo.id) {
      url += `&user_id=${userInfo.id}`;
    }
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    
    if (!data.posts || data.posts.length === 0) {
      setHasMore(false);
      if (page === 0) setShowSettings(true);
    } else {
      setPosts(prevPosts => [...prevPosts, ...data.posts]);
      setHasMore(data.posts.length === POSTS_PER_PAGE);
      setPage(prevPage => prevPage + 1);
    }
  } catch (error) {
    console.error('Error fetching posts:', error);
    setError(error.message || 'Failed to fetch posts. Please try again later.');
    setHasMore(false);  // Prevent further API calls
    setShowSettings(true);
  } finally {
    setLoading(false);
  }
}, [userInfo, loading, hasMore, page, initializeFeedSettings]);


  useEffect(() => {
    if (!locationPrompted) {
      initializeFeedSettings();
    }
  }, [locationPrompted, initializeFeedSettings]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !loading && hasMore) {
          fetchPosts();
        }
      },
      { threshold: 0.1 }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [fetchPosts, loading, hasMore]);

  const handleNewPost = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    //setPosts([]);
    //setPage(0);
    //setHasMore(true);
    
    //fetchPosts();
  };

  const handleSettingsChange = (newSettings) => {
    setShowSettings(false);
    setPosts([]);
    setPage(0);
    setHasMore(true);
    localStorage.setItem('feedSettings', JSON.stringify(newSettings));
    fetchPosts(newSettings);
  };
  
  const userStateInfo = useSelector(state => state.user);
  let isLoggedIn = false;
  if (userStateInfo)
    isLoggedIn = userStateInfo.isLoggedIn;
 
 return (
    <div className={styles.feedContainer}>
      
      {isLoggedIn ? (
        <NewPostContainer onNewPostClick={handleNewPost} />
      ) : (

        <RegisterPromptContainer />
      )}

      {posts.map(post => (
        <Post key={post.id} post={post} />
      ))}
      {hasMore && (
        <div ref={loadingRef} className={styles.loadingIndicator}>
          <Loader className={styles.loadingIcon} />
          <span>Loading more posts</span>
        </div>
      )}
      {error && <p className={styles.errorMessage}>{error}</p>}
      
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <NewPostForm onClose={handleCloseModal} />
        </Modal>
      )}
      {showSettings && (
        <Modal onClose={() => setShowSettings(false)}>
          <NoPostsFoundSettings 
            onSettingsChange={handleSettingsChange}
            onClose={() => setShowSettings(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default FeedPage;
