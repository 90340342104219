import React from 'react';
import styles from './Loading.module.css';
import feedIcon from './navbar-images/peep.png';

const Loading = ({ message = "Logging in..." }) => {
  return (
    <div className={styles.loadingContainer}>
      <img src={feedIcon} alt="Peep" className={styles.peepImage} />
      <p className={styles.loadingText}>{message}</p>
    </div>
  );
};

export default Loading;