import React, { useState, useEffect } from 'react';
import MultiRangeSlider from '../components/MultiRangeSlider';
import styles from './SettingsPage.module.css';
import ToggleSwitch from '../components/ToggleSwitch';
import { useNavigate } from 'react-router-dom';

function SettingsPage() {
  const feedSettings = JSON.parse(localStorage.getItem('feedSettings'));
  const [distance, setDistance] = useState({ min: 1, max: 100 });
  const [ageRange, setAgeRange] = useState({ min: 18, max: 100 });

  const [notificationsOn, setNotificationsOn] = useState(true);
  const [locationOn, setLocationOn] = useState(true);
  //const feedSettings = JSON.parse(localStorage.getItem('feedSettings'));
  const navigate = useNavigate();

 const handleUpdatePeeps = (e) => {
    e.preventDefault();
   
    navigate('/feed');
    // Add your logic here to update peeps
  };

  const handleAccountManagement = (e) => {
    e.preventDefault();
    
    // Add your logic here for account management navigation
  };

  const handleLogout = (e) => {
    e.preventDefault();
    
    navigate('/logout');
    // Add your logout logic here


  };

  const handlePrivacyStatement = (e) => {
      e.preventDefault();
      navigate('/privacy');
    };



  const handleReportBugs = (e) => {
    e.preventDefault();
    navigate('/bug')
    // Add your bug reporting logic here
  };

  const handleCareers = (e) => {
    e.preventDefault();
    navigate('/careers')
  }

  const handleDistanceChange = (values) => {
    setDistance(values);
    
    feedSettings.distance = {
      min: values.min,
      max: values.max
    }
    localStorage.setItem('feedSettings', JSON.stringify(feedSettings));
  };

  const handleAgeRangeChange = (values) => {
    setAgeRange(values);
    
    feedSettings.ageRange = {
      min: values.min, 
      max: values.max
    }
    localStorage.setItem('feedSettings', JSON.stringify(feedSettings));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
    <div className={styles.settingsWrapper}>
      <div className={styles.settingsContainer}>
        <h1 className={styles.settingsTitle}>Settings</h1>
        <div className={styles.settingItem}>
          <label>Preferred Distance (miles)</label>
          <MultiRangeSlider
            min={0}
            max={100}
            initialMin={feedSettings.distance.min}
            initialMax={feedSettings.distance.max}
            onChange={handleDistanceChange}
          />
          <span className={styles.rangeDisplay}>
            {feedSettings.distance.min} - {feedSettings.distance.max}
          </span>
        </div>
        <div className={styles.settingItem}>
          <label>Preferred Age Range (years)</label>
          <MultiRangeSlider
            min={18}
            max={100}
            initialMin={feedSettings.ageRange.min}
            initialMax={feedSettings.ageRange.max}
            onChange={handleAgeRangeChange}
          />
          <span className={styles.rangeDisplay}>
            {feedSettings.ageRange.min} - {feedSettings.ageRange.max}
          </span>
        </div>
        <div className={styles.settingItem}>
          <div className={styles.toggleContainer}>
            <ToggleSwitch
              isOn={notificationsOn}
              handleToggle={() => setNotificationsOn(!notificationsOn)}
            />
            <p className={styles.toggleText}>Show peeps slightly outside of my preferred age range.</p>
          </div>
        </div>
        <div className={styles.settingItem}>
          <div className={styles.toggleContainer}>
            <ToggleSwitch
              isOn={locationOn}
              handleToggle={() => setLocationOn(!locationOn)}
            />
            <p className={styles.toggleText}>Show peeps slightly outside of my preferred distance.</p>
          </div>
        </div>
        <div className={styles.settingItem}>
          <div className={styles.golink}>
            <a href="#" onClick={handleUpdatePeeps}>Update Preference</a>
          </div>
        </div>
        <div className={styles.settingItem}>
          <div className={styles.golink}>
            <a href="#" onClick={handleAccountManagement}>Account Management</a>
          </div>
        </div>
        <div className={styles.settingItem}>
          <div className={styles.golink}>
            <a href="#" onClick={handleLogout}>Logout</a>
          </div>
        </div>
    <div className={styles.settingItem}>
              <div className={styles.golink}>
                <a href="#" onClick={handleReportBugs}>Report Bugs</a>
              </div>
            </div>

    <div className={styles.settingItem}>
              <div className={styles.golink}>
                <a href="#" onClick={handlePrivacyStatement}>Privacy Statement</a>
              </div>
    </div>

     <div className={styles.settingItem}>
              <div className={styles.golink}>
                <a href="#" onClick={handleCareers}>Careers</a>
              </div>
    </div>
    <div className={styles.settingItem}>
      <label>v0.1.4</label>
      </div>


          </div>
        </div>
      );
}

export default SettingsPage;
