import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, ZoomControl } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import feedIcon from '../components/navbar-images/peep.png';
import styles from './MapPage.module.css';
import { useNavigate } from 'react-router-dom';
import LocationGetter from '../components/LocationGetter';

const feedMarkerIcon = L.icon({
  iconUrl: feedIcon,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32]
});

const MapEvents = React.memo(({ onPositionChange }) => {
  useMapEvents({
    click(e) {
      onPositionChange([e.latlng.lat, e.latlng.lng]);
    },
  });
  return null;
});


const MapPage = () => {
  const [position, setPosition] = useState(getInitialPosition);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const { getLocationCoordinates } = LocationGetter();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePositionChange = useCallback((newPosition) => {
    setPosition(newPosition);
    updateFeedSettings(newPosition);
  }, []);

  const handleHomeClick = useCallback(async (e) => {
    e.preventDefault();
    try {
      const [latitude, longitude] = await getLocationCoordinates();
      handlePositionChange([latitude, longitude]);
      mapRef.current?.panTo([latitude, longitude]);
    } catch (error) {
      console.error('Error getting location:', error);
      // TODO: Handle error (e.g., show user notification)
    }
  }, [getLocationCoordinates, handlePositionChange]);

  const handleGoClick = useCallback((e) => {
    e.preventDefault();
    navigate('/feed');
  }, [navigate]);

  const fetchSuggestions = useCallback(async (query) => {
    if (query.length < 3) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`);
      const data = await response.json();
      setSuggestions(data.slice(0, 5)); // Limit to 5 suggestions
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    }
  }, []);


const handleInputFocus = useCallback(() => {
  setSearchQuery('');
  setSuggestions([]);
}, []);


  const handleSearchChange = useCallback((e) => {
    
    const query = e.target.value;
    setSearchQuery(query);
    fetchSuggestions(query);
  }, [fetchSuggestions]);

  const handleSuggestionClick = useCallback((suggestion) => {
    setSearchQuery(suggestion.display_name);
    setSuggestions([]);
    handlePositionChange([parseFloat(suggestion.lat), parseFloat(suggestion.lon)]);
    mapRef.current?.panTo([parseFloat(suggestion.lat), parseFloat(suggestion.lon)]);
  }, [handlePositionChange]);

  const mapEventsMemo = useMemo(() => <MapEvents onPositionChange={handlePositionChange} />, [handlePositionChange]);

  return (
    <div className={styles.MapButton}>
      <div className={styles.mapContainer}>
        <div className={styles.mapWrapper}>
          <MapContainer
            center={position}
            zoom={14}
            style={{ height: '100%', width: '100%' }}
            ref={mapRef}
            zoomControl={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
            />
            <Marker position={position} icon={feedMarkerIcon}>
              <Popup className="popup">
                Move your peep to see <br /> what people are doing globally!
              </Popup>
            </Marker>
            {mapEventsMemo}
            <ZoomControl position="topright" />
          </MapContainer>
          
          <div className={styles.searchBar}>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              onFocus={handleInputFocus}
              placeholder="Search for a city..."
            />
            {suggestions.length > 0 && (
              <ul className={styles.suggestions}>
                {suggestions.map((suggestion) => (
                  <li
                    key={suggestion.place_id}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion.display_name}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className={styles.mapButtons}>
            <button onClick={handleHomeClick} className={styles.mapButton}>Home</button>
            <button onClick={handleGoClick} className={styles.mapButton}>Go</button>
          </div>
        </div>
      </div>
    </div>
  );
};


function getInitialPosition() {

  try {
    const feedSettings = JSON.parse(localStorage.getItem('feedSettings'));
   

    if (feedSettings && feedSettings.position && 
        typeof feedSettings.position.lat === 'number' && 
        typeof feedSettings.position.lng === 'number' &&
        !isNaN(feedSettings.position.lat) && !isNaN(feedSettings.position.lng)) {
   
      return [feedSettings.position.lat, feedSettings.position.lng];
    }
  } catch (error) {
    console.error('Error parsing feedSettings:', error);
  }

  // Fallback to localStorage lat/lon
  const lat = parseFloat(localStorage.getItem("lat"));
  const lon = parseFloat(localStorage.getItem("lon"));

  if (!isNaN(lat) && !isNaN(lon)) {
    
    return [lat, lon];
  }

  // Default position if everything else fails

  return [51.505, -0.09]; // Default to London coordinates
}


function updateFeedSettings(newPosition) {
  try {
    const feedSettings = JSON.parse(localStorage.getItem('feedSettings')) || {};
    feedSettings.position = {
      lat: newPosition[0],
      lng: newPosition[1]
    };
    localStorage.setItem('feedSettings', JSON.stringify(feedSettings));
  } catch (error) {
    console.error('Error updating feedSettings:', error);
  }
}

export default React.memo(MapPage);