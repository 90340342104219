import React from 'react';
import styles from './NewPostContainer.module.css';
import { useUserInfo } from '../hooks/userUserInfo';


const NewPostContainer = ({ onNewPostClick }) => {
    const userInfo = useUserInfo();
    return (
        <div className={styles.newPostContainer}>
            <div className={styles.userInfo}>
                <img 
                    src={userInfo.avatarUrl} 
                    alt={userInfo.username} 
                    className={styles.avatar} 
                />
                <span className={styles.username}>{userInfo.username}</span>
            </div>
            <div className={styles.inputArea}>
                <div className={styles.inputPlaceholder} onClick={onNewPostClick}>
                    <span className={styles.placeholderText}>Tell your peeps...</span>
                </div>
                <button className={styles.postButton}>Post</button>
            </div>
        </div>
    );
};

export default React.memo(NewPostContainer);
