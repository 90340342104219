import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Navbar.module.css';
import mapIcon from './navbar-images/map.png';
import feedIcon from './navbar-images/peep.png';
import messagesIcon from './navbar-images/mail.png';
import settingsIcon from './navbar-images/gear.png';
import pencilIcon from './navbar-images/pencil.svg';
import { useAuth } from '../hooks/useAuth';

const Navbar = () => {
  const location = useLocation();
  const isLoggedIn = useAuth();

  const navItems = [
    { to: '/map', icon: mapIcon, alt: 'Map' },
    { to: '/feed', icon: feedIcon, alt: 'Feed' },
    { to: '/messages', icon: messagesIcon, alt: 'Messages' },
    { to: '/settings', icon: settingsIcon, alt: 'Settings' },
  ];

  return (
    <nav className={`navbar ${styles.navbar}`}>
      <ul className={styles.navList}>
      <li className={styles.navItem}>
          <Link to="/feed" className={`${styles.navLink} ${styles.peepsLink}`}>
          peepnest
          </Link>
        </li>
        {navItems.map((item, index) => (
          <li key={index} className={styles.navItem}>
            <Link to={item.to} className={`${styles.navLink}`}>
              <img src={item.icon} alt={item.alt} className={styles.navIcon} />
              
            </Link>
          </li>
        ))}

      </ul>
    </nav>
  );
};
export default Navbar;
